/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components), {TableOfContents, MapInfo, LandingPageCta, ThreeColCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "water-resistant-hearing-aids-what-you-need-to-know",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#water-resistant-hearing-aids-what-you-need-to-know",
    "aria-label": "water resistant hearing aids what you need to know permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Water-resistant hearing aids: what you need to know"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing loss shouldn’t stop you from having a good time and enjoying outdoor activities. Thankfully, water-resistant hearing aids provide excellent clarity and maximum wearing comfort, so that you can live your life to the fullest! Many of the ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/",
    className: "c-md-a"
  }, "top hearing aid manufacturers"), " offer devices that are water-resistant. But what exactly does “water-resistant” mean? We explore this question and more in the section below."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-exactly-is-a-water-resistant-hearing-aid",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-exactly-is-a-water-resistant-hearing-aid",
    "aria-label": "what exactly is a water resistant hearing aid permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What exactly is a water-resistant hearing aid?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When a hearing aid is water resistant, the device can continue working properly, even when it is exposed to a small amount of moisture for a short period of time. However, long-time exposure to humidity could potentially damage its delicate parts and cause it to stop functioning. You can dry out hearing aids with a dehumidifier, which can help air out water or absorb any moisture. It’s important to remember that ", React.createElement(_components.strong, null, "water-resistant does not mean waterproof"), ". In other words, you shouldn’t jump in a pool or take as shower while wearing a water-resistant hearing aid. The good news is, you won’t have to worry about a few drops of rain or sweat damaging your device."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "What about waterproof hearing aids? Do they exist? Unfortunately, ", React.createElement(_components.strong, null, "there are no waterproof hearing aids available on the market"), ". A few years ago, the manufacturer ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/",
    className: "c-md-a"
  }, "Siemens (now Signia)"), " introduced a totally waterproof device called Aquaris. However, the device was eventually discontinued. The good news is, with all the innovative advancements in ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/",
    className: "c-md-a"
  }, "hearing aid technology"), ", you can be sure to see a waterproof hearing aid in the near future. So, stay tuned!"), "\n", React.createElement(MapInfo), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "why-water-resistant-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-water-resistant-hearing-aids",
    "aria-label": "why water resistant hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why water-resistant hearing aids?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When choosing a hearing aid, it’s important to find a model that matches your individual needs and preferences. This is why it is important to ", React.createElement(_components.a, {
    href: "/audiologists/",
    className: "c-md-a"
  }, "speak to a hearing specialist or audiologist"), " about all your options. Water-resistant hearing aids may be suitable for you if you live in a humid area, have oily skin, produce a lot of earwax, or are concerned about sweat. A hear.com expert would be glad to help you determine if a water-resistant hearing aid is right for you."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "taking-care-of-your-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#taking-care-of-your-hearing-aids",
    "aria-label": "taking care of your hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Taking care of your hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Your devices may be water resistant, but that doesn’t mean you should neglect taking care of them. They are advanced minicomputers that require daily maintenance to ensure they last you a long time. Therefore, your care procedure should include cleaning the devices and ", React.createElement(_components.a, {
    href: "/hearing-aids/batteries/",
    className: "c-md-a"
  }, "replacing the batteries"), " when needed."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Do you think you would benefit from hearing aids that could resist small amounts of moisture and water? Then you need to check out some of the most advanced and durable hearing aids hear.com has to offer! Don’t let hearing loss slow you down. Let one of our hearing experts help you find the best water-resistant hearing aids – tailored to your lifestyle and budget."), "\n", React.createElement(LandingPageCta, {
    copy: "GET THE BEST WATER RESISTANT HEARING AIDS",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/resources/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
